<template>
  <div class='nav'>
    <div class='header'>
      <img @click="gszl()" src="./assets/back.png">
      开奖记录
      <img @click="gszl()" src="./assets/home.png">
    </div>
    <div class='kind'>
      <div v-for="(v,i) in kinds" :key="i" :value='i' @click='kindId=i' :class="kindId==i?'act':''">
        <img :src="'static/nav'+i+'.png'">
        <p>{{v}}</p>
      </div>
      <div @click="gszl()">
        <img src="static/nav5.png">
        <p>高手资料</p>
      </div>
    </div>
    <div class='select' v-show="kindId!=4">
      <select v-model="id">
        <option v-for="(v,i) in nav" :key="i" :value="i">
          {{v}}
        </option>
      </select>
      <select v-model="year">
        <option value="0">
          选择年份
        </option>
        <option v-for="(v,i) in years" :key="i" :value="v.year">
          {{v.year}}年
        </option>
      </select>
      <select v-model="qis">
        <option value="10">
          最新10期
        </option>
        <option value="50">
          最新50期
        </option>
        <option value="100">
          最新100期
        </option>
        <option value="200">
          最新200期
        </option>
        <option value="500">
          最新500期
        </option>
      </select>
    </div>
  </div>
  <div style="height:140px"></div>
  <transition>
  <History v-if='kindId==0' :data="data"/>
  </transition>
  <transition>
  <Zoushi v-if='kindId==1' :data="data"/>
  </transition>
  <transition>
  <Tongji v-if='kindId==2' :data="data"/>
  </transition>
  <transition>
  <Yilou v-if='kindId==3' :data="data"/>
  </transition>
  <transition>
  <Kjdate v-if='kindId==4' />
  </transition>
</template>

<script>
import History from './components/History.vue'
import Zoushi from './components/Zoushi.vue'
import Tongji from './components/Tongji.vue'
import Yilou from './components/Yilou.vue'
import Kjdate from './components/Kjdate.vue'

export default {
  name: 'App',
  components: {
    History,Zoushi,Tongji,Yilou,Kjdate
  },
  data(){
    return{
      api:'https://ls.kjkj.fit',
      // api:'',
      kindId:0,
      kinds:['历史记录','走势图表','开奖统计','遗漏统计','开奖日期'],
      id:0,
      year:0,
      qis:50,
      code:['am','48am','xg','tw','xjp'],
      nav:['新澳门六合彩','澳门六合彩','香港六合彩','台湾六合彩','新加坡六合彩'],
      urls:["https://kj.48kk.homes:1888","https://kj.48kk.homes:1888","https://135hk.am66.biz:1888","https://tw.txzl8.biz:1888","https://xjp.txzl8.biz:1888"],
      years:[],
      data:[]
    }
  },
  created() {
    let nt = Date.parse(new Date())/1000;
    let st = this.$session.get('time');
    if(st==null){
      this.$session.set('time',nt);
    }else{
      let vt = nt-st;
      if(vt>1200){
        this.$session.clear();
        this.$session.set('time',nt);
      }
    }
    // this.$session.clear();
    let type = window.location.search;
    switch (type) {
        case '?amdate':
            this.id = 0;
            this.kindId = 4;
            break;
        case '?xgdate':
            this.id = 1;
            this.kindId = 4;
            break;
        case '?am':
            this.id = 0;
            break;
        case '?48am':
            this.id = 1;
            break;
        case '?xg':
            this.id = 2;
            break;
        case '?tw':
            this.id = 3;
            break;
        case '?xjp':
            this.id = 4;
            break;
        default:
            this.id = 0;
            break;
    }
    this.getyears(this.id);
    this.getdata(this.id);
  },
  watch:{
    data:function(){
      window.scrollTo(0,0);
    },
    id:function(val){
      this.data = [];
      this.year = 0;
      this.getyears(val);
      this.getdata(val);
      this.setqisdata(this.qis);
    },
    year:function(val){
      this.data = [];
      if(val!=0){
        this.getyeardata(val);
      }else{
        this.getdata(this.id);
      }
    },
    qis:function(val){
      this.setqisdata(val);
    },
    kindId:function(){
      window.scrollTo(0,0);
    }
  },
  methods:{
    gszl:function(){
      let url = this.urls[this.id];
      location.href = url;
    },
    getyears:function(id){
      let years = this.$session.get('kjyears'+id);
      if(years){
        this.years = years;
      }else{
        this.$http.post(this.api+'/kjyears',{'g':this.code[id]},true).then((result) => {
          this.years = result.data.data;
          this.$session.set('kjyears'+id,result.data.data);
        });
      }
    },
    getdata:function(id){
      let data = this.$session.get('kjdata'+id);
      if(data){
        this.setqisdata(this.qis);
      }else{
        this.$http.post(this.api+'/kj',{'g':this.code[id],'s':500},true).then((result) => {
          this.data = result.data.data;
          this.$session.set('kjdata'+id,result.data.data);
          this.setqisdata(this.qis);
        });
      }
    },
    getyeardata:function(year){
      let data = this.$session.get(this.code[this.id]+year);
      if(data){
        this.setqisdata(this.qis);
      }else{
        this.$http.post(this.api+'/kj',{'g':this.code[this.id],'y':year},true).then((result) => {
          this.data = result.data.data;
          this.$session.set(this.code[this.id]+year,result.data.data);
          this.setqisdata(this.qis);
        });
      }
    },
    setqisdata:function(qis){
      let data = [];
      if(this.year==0){
        data = this.$session.get('kjdata'+this.id);
      }else{
        data = this.$session.get(this.code[this.id]+this.year);
      }
      if(data!==null){
        if(qis<data.length){
          this.data = data.slice(0,qis);
        }else{
          this.data = data;
        }
      }
    }
  }

}
</script>

<style>
*{
  margin: 0;padding: 0;list-style: none;
}
body{background: #000;}
#app {
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  background: #fff;
}
.nav{
  background: #fff;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  max-width: 720px;
  z-index: 99;
}
.nav .select,.nav .kind{
  display: flex;
  justify-content: space-between;
}
.nav .header{
  background: linear-gradient(to top, #41d843, #1c6109);
  height: 36px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  line-height: 36px;
}
.nav .header img{
  width: 26px;
  height: 26px;
  margin: 5px 6px;
}
.nav .kind div{
  width: 20%;
  padding: 5px 0;
  font-size: 14px;
}
.nav .kind div img{
  width: 46px;
}
.nav .kind .act{
  color: #ff6500;
  text-decoration: underline;
}
.nav select{
  width: 33.3%;
  padding: 3px 0;
  text-align: center;
  border: 1px solid #ff9200;
  background: #fff;
  outline: none;
}
.c01,.c02,.c07,.c08,.c12,.c13,.c18,.c19,.c23,.c24,.c29,.c30,.c34,.c35,.c40,.c45,.c46{
	background-image: url(assets/g-red.png);
}
.c03,.c04,.c09,.c10,.c14,.c15,.c20,.c25,.c26,.c31,.c36,.c37,.c41,.c42,.c47,.c48{
	background-image: url(assets/g-blue.png);
}
.c05,.c06,.c11,.c16,.c17,.c21,.c22,.c27,.c28,.c32,.c33,.c38,.c39,.c43,.c44,.c49{
	background-image: url(assets/g-green.png);
}
.ball{
  background: #ffc300;
  border-radius: 50%;
  color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.6rem;
  display: block;
  text-align: center;
  margin: 0 auto;
}
.ball0{background: #f00;}
.ball1{background: #00a149;}
.ball2{background: #2c98ff;}
.v-enter-active{
  transition: all .3s;
}
.v-enter-from{
  opacity: 0;
  transform: translateX(-10rem);
}
.jiazai{
    line-height: 40px;
    color: #ff7d27;
    letter-spacing: 3px;
    font-size: 18px;
}
.line-enter-active{
  transition: all .3s;
}
.line-enter-from{
  opacity: 0;
  transform: translateX(-10rem);
}
.line-move{
  transition: transform .6s;
}
</style>
